import React from "react"
import PropTypes from "prop-types"

import { useColorMode } from "theme-ui"
import Box from "./box"

import paperBackgroundLight from "../images/kurse/bg-paper-light.svg"
import paperBackground from "../images/kurse/bg-paper.svg"

const Paper = ({ clipBottom, p, children, sx }) => {
  const [colorMode] = useColorMode()

  return (
    <Box
      sx={{
        p: p,
        backgroundColor: "letter",
        backgroundImage: `url("${
          colorMode === "light" ? paperBackgroundLight : paperBackground
        }")`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        clipPath: clipBottom
          ? "polygon(100% 94.05%, 97.58% 96.25%, 95.02% 92.12%, 92.3% 95.21%, 89.44% 91.94%, 87.44% 95.45%, 84.57% 92.67%, 81.03% 96.64%, 77.09% 87.09%, 73.38% 96.58%, 70.42% 92.08%, 67.82% 96.01%, 65.21% 90.69%, 63.87% 95%, 59.85% 96.43%, 56.95% 91.74%, 53.92% 95.83%, 50.61% 94.64%, 46.93% 97.12%, 43.29% 92.92%, 39.34% 96.76%, 36.94% 92.26%, 32.49% 90.48%, 30.22% 97.62%, 24.82% 92.26%, 20.5% 99.85%, 16.99% 97.02%, 13.33% 100%, 8.8% 97.02%, 4.35% 99.54%, 0% 95%, 0% 0%, 100% 0px)"
          : null,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

Paper.propTypes = {
  clipBottom: PropTypes.bool,
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
}

Paper.defaultProps = {
  clipBottom: false,
  p: [6, 8, 12],
}

export default Paper
