import React from "react"

import Note from "../../../../components/note"
import Text from "../../../../components/text"

const LetterNote = () => {
  return (
    <Note variant="task">
      <>
        Wie wirkt Hölderlin in diesem Brief auf dich? Halte deine Eindrücke
        fest, indem du die Regler verschiebst. Das Stimmungsbarometer reicht von{" "}
        <Text as="span" italic>
          nicht vorhanden
        </Text>{" "}
        (ganz links) bis zu{" "}
        <Text as="span" italic>
          stark vorhanden
        </Text>{" "}
        (ganz rechts).{" "}
      </>
    </Note>
  )
}

export default LetterNote
